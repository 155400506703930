import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from "../components/layout/Layout";
import { Tags } from "../components/tags/Tags";
import { Share } from "../components/share/Share";
import ScrollProgress from "../components/scroll-progress/ScrollProgress";
import Posts from "../components/posts/Posts";
import AffiliateLink from "../components/affiliate-link/AffiliateLink";
import { PostPreview } from "../components/post-preview/PostPreview";
import { Breadcrumbs } from "../components/breadcrumbs/Breadcrumbs"

import classNames from "classnames/bind";
import styles from "./Post.module.scss";

export const query = graphql
    `query EventByID(
        $slug: String!,
        $type: String!,
        $cat: String!
    ) {
        site {
            siteMetadata {
                siteUrl
            }
        }

        related: allMdx(
            limit: 5
            filter: {
                fields: {
                    due: {ne: "past"}
                },
                frontmatter: {
                    category: {eq: $cat},
                    type: {eq: $type}
                },
                slug: {ne: $slug}
            }
            sort: {
                fields: [frontmatter___startDate],
                order: ASC
            }
        ) {
            nodes {
                slug
                frontmatter {
                    category
                    date(formatString: "MMM Do, YYYY")
                    dateSimple: date(formatString: "YYYY-MM-DD")
                    released(formatString: "MMM Do, YYYY")
                    releasedSimple: released(formatString: "YYYY-MM-DD")
                    excerpt
                    image {
                        childImageSharp {
                            gatsbyImageData(
                                width: 254,
                                quality: 80,
                                formats: [WEBP, JPG],
                                layout: CONSTRAINED
                            )
                        }
                    }
                    length
                    title
                    type
                    location
                    startDate(formatString: "MMM Do, YYYY")
                    startDateSimple: startDate(formatString: "YYYY-MM-DD HH:mm:ss")
                    endDate(formatString: "MMM Do, YYYY")
                    endDateSimple: endDate(formatString: "YYYY-MM-DD HH:mm:ss")
                    startTime
                    endTime
                    productUrl
                }
            }
        }

        mdx(slug: {eq: $slug}) {
            body
            slug
            frontmatter {
                author {
                    friendlyName
                    page
                }
                image {
                    childImageSharp {
                        gatsbyImageData(
                            quality: 80,
                            layout: FULL_WIDTH
                        )
                        fixed(
                            toFormat: JPG
                            cropFocus: ATTENTION
                            height: 800
                            width: 1200
                            jpegQuality: 100
                        ) {
                            src
                        }
                    }
                }
                title
                date(formatString: "MMM Do, YYYY")
                dateSimple: date(formatString: "YYYY-MM-DD HH:mm:ss")
                type
                category
                tags
                homepage
                location
                startDate(formatString: "MMM Do, YYYY")
                startDateSimple: startDate(formatString: "YYYY-MM-DD")
                endDate(formatString: "MMM Do, YYYY")
                endDateSimple: endDate(formatString: "YYYY-MM-DD")
                startTime
                endTime
                platform
                agerating
                director
                developer
                publisher

                productImage {
                    childImageSharp {
                        gatsbyImageData(
                            formats: [WEBP, JPG]
                            width: 172
                            quality: 100
                            layout: CONSTRAINED
                        )
                    }
                }
                productUrl
            }
        }
    }
`

const Event = ({ data, location }) => {

    const { body, frontmatter } = data.mdx;

    const cx = classNames.bind(styles);

    const contentClasses = cx(
        "content",
        "event"
    );

    return (
        <Layout
            location={location}
            type={"event"}
            title={frontmatter.title}
        >
            <GatsbySeo
                openGraph={{
                    title: frontmatter.title,
                    description: frontmatter.excerpt,
                    url: location.href,
                    type: "article",
                    article: {
                        publishedTime: frontmatter.dateSimple,
                        section: frontmatter.category,
                        authors: [
                            data.site.siteMetadata.siteUrl + frontmatter.author.page
                        ],
                        tags: frontmatter.tags.map(tag => { return tag }),
                    },
                    images: [
                        {
                            url: data.site.siteMetadata.siteUrl + frontmatter.image.childImageSharp.fixed.src,
                            width: 1200,
                            height: 800,
                            alt: frontmatter.title,
                        },
                    ],
                }}
            />

            <ScrollProgress />

            <div className={contentClasses}>
                <div className={styles.primary}>
                    <Breadcrumbs 
                        category={frontmatter.category}
                        type={frontmatter.type}
                        className={styles.breadcrumbs}
                    />
                    <PostPreview
                        slug={`/${data.mdx.slug}`}
                        frontmatter={frontmatter}
                        className={styles.preview}
                        hideCat
                        noLink
                    />
                    <div className={styles.body}>
                        <MDXRenderer>{body}</MDXRenderer>

                        {frontmatter.homepage &&
                            <p className={styles.homepage}>
                                <a 
                                    href={frontmatter.homepage}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Visit the homepage for more info
                                </a>
                            </p>
                        }
                    </div>

                    {frontmatter.productUrl &&
                        <AffiliateLink
                            title={frontmatter.title}
                            url={frontmatter.productUrl}
                            image={frontmatter.productImage ? frontmatter.productImage.childImageSharp.gatsbyImageData : null}
                            className={styles.bottomAd}
                        />
                    }
                </div>

                <div className={styles.secondary}>
                    {frontmatter.tags && frontmatter.tags.length > 0 &&
                        <Tags
                            className={styles.tags}
                            title={"Tags"}
                            tags={frontmatter.tags}
                        />
                    }

                    <Share
                        className={styles.share}
                        title={`Share this ${frontmatter.type}`}
                        currentUrl={location.href}
                        pageTitle={frontmatter.title}
                    />

                    {data.related.nodes.length > 0 &&
                        <div className={styles.related}>
                            <h3 className={styles.subTitle}>Related {frontmatter.type}s</h3>
                            <Posts
                                hideCat
                                data={data.related}
                            />
                        </div>
                    }
                </div>
            </div>
        </Layout>
    );
}

export default Event;