import React from "react";
import PropTypes from "prop-types";

import classNames from "classnames";
import * as styles from "./Icons.module.scss";

export const Amazon = ({ className }) => {

    const cx = classNames.bind(styles);

    const classes = cx(
        "wrapper",
        className
    );

    return (
        <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="100.724" height="30.354" viewBox="0 0 100.724 30.354">
            <path d="M189.008,196.639c-5.853,4.314-14.337,6.616-21.642,6.616a39.162,39.162,0,0,1-26.438-10.089c-.548-.5-.057-1.171.6-.785A53.211,53.211,0,0,0,167.98,199.4a52.6,52.6,0,0,0,20.177-4.126c.991-.421,1.82.649.851,1.368" transform="translate(-126.521 -172.901)" fill="#f90" fill-rule="evenodd"/>
            <path d="M579.209,184.455c-.745-.956-4.946-.452-6.831-.228-.574.07-.662-.43-.145-.789,3.345-2.354,8.835-1.675,9.475-.886s-.167,6.3-3.31,8.922c-.482.4-.943.189-.728-.346.706-1.763,2.289-5.713,1.539-6.673" transform="translate(-514.288 -163.502)" fill="#f90" fill-rule="evenodd"/>
            <path d="M572.82,7.308V5.019a.565.565,0,0,1,.579-.579h10.246a.571.571,0,0,1,.592.579v1.96a3.225,3.225,0,0,1-.772,1.438L578.156,16A11.313,11.313,0,0,1,584,17.252a1.043,1.043,0,0,1,.544.89v2.442c0,.333-.368.723-.754.522a11.8,11.8,0,0,0-10.825.018c-.355.193-.728-.193-.728-.526V18.278a2.767,2.767,0,0,1,.377-1.574l6.151-8.822h-5.353a.567.567,0,0,1-.592-.574" transform="translate(-514.599 -3.993)" fill="#fff" fill-rule="evenodd"/>
            <path d="M174.135,18.965h-3.117a.589.589,0,0,1-.557-.531v-16a.585.585,0,0,1,.6-.574h2.907a.587.587,0,0,1,.566.535V4.488h.057a4.115,4.115,0,0,1,4.1-2.964c1.951,0,3.17.943,4.047,2.964a4.6,4.6,0,0,1,7.914-1.214c.987,1.346.785,3.3.785,5.016l0,10.1a.589.589,0,0,1-.6.579h-3.113a.593.593,0,0,1-.561-.579V9.907a20.021,20.021,0,0,0-.088-3,1.623,1.623,0,0,0-1.833-1.377,2.071,2.071,0,0,0-1.863,1.311,9.684,9.684,0,0,0-.289,3.065v8.48a.589.589,0,0,1-.6.579h-3.113a.589.589,0,0,1-.561-.579l0-8.48c0-1.784.294-4.411-1.92-4.411-2.24,0-2.153,2.561-2.153,4.411v8.48a.589.589,0,0,1-.6.579" transform="translate(-153.291 -1.37)" fill="#fff" fill-rule="evenodd"/>
            <path d="M716.326,1.524c4.626,0,7.129,3.972,7.129,9.023,0,4.88-2.767,8.751-7.129,8.751-4.542,0-7.015-3.972-7.015-8.922,0-4.981,2.5-8.852,7.015-8.852m.026,3.266c-2.3,0-2.442,3.131-2.442,5.082S713.879,16,716.326,16c2.416,0,2.53-3.367,2.53-5.419a14.788,14.788,0,0,0-.465-4.244,1.95,1.95,0,0,0-2.039-1.548" transform="translate(-637.866 -1.37)" fill="#fff" fill-rule="evenodd"/>
            <path d="M876.52,18.965h-3.1a.593.593,0,0,1-.561-.579l0-16a.59.59,0,0,1,.6-.522h2.889a.6.6,0,0,1,.557.447V4.755h.057c.873-2.188,2.1-3.231,4.248-3.231a4.091,4.091,0,0,1,3.639,1.885c.816,1.28.816,3.433.816,4.981V18.461a.6.6,0,0,1-.6.5h-3.126a.591.591,0,0,1-.553-.5V9.771c0-1.749.2-4.31-1.951-4.31a2.037,2.037,0,0,0-1.8,1.28,7.08,7.08,0,0,0-.5,3.03v8.615a.6.6,0,0,1-.609.579" transform="translate(-784.934 -1.37)" fill="#fff" fill-rule="evenodd"/>
            <path d="M414.923,9.953a6.1,6.1,0,0,1-.583,3.306,2.533,2.533,0,0,1-2.157,1.416c-1.2,0-1.9-.912-1.9-2.258,0-2.657,2.381-3.139,4.639-3.139v.675m3.144,7.6a.651.651,0,0,1-.737.075,7.6,7.6,0,0,1-1.789-2.078,6.173,6.173,0,0,1-5.139,2.267c-2.626,0-4.665-1.618-4.665-4.858a5.291,5.291,0,0,1,3.323-5.095,21.6,21.6,0,0,1,5.862-1.083v-.4a3.976,3.976,0,0,0-.381-2.258,2.075,2.075,0,0,0-1.749-.811,2.372,2.372,0,0,0-2.5,1.872.652.652,0,0,1-.544.57l-3.021-.324a.549.549,0,0,1-.465-.653C406.952,1.105,410.266,0,413.23,0a7.067,7.067,0,0,1,4.7,1.552c1.517,1.416,1.372,3.306,1.372,5.362v4.858a4.263,4.263,0,0,0,1.175,2.889.589.589,0,0,1-.013.829c-.636.531-1.767,1.517-2.39,2.069l0-.009" transform="translate(-364.87 0)" fill="#fff" fill-rule="evenodd"/>
            <path d="M9.185,9.953A6.1,6.1,0,0,1,8.6,13.259a2.528,2.528,0,0,1-2.157,1.416c-1.2,0-1.894-.912-1.894-2.258,0-2.657,2.381-3.139,4.634-3.139v.675m3.144,7.6a.651.651,0,0,1-.737.075A7.675,7.675,0,0,1,9.8,15.548a6.168,6.168,0,0,1-5.139,2.267C2.043,17.814,0,16.2,0,12.956A5.3,5.3,0,0,1,3.323,7.862,21.6,21.6,0,0,1,9.185,6.779v-.4a4.011,4.011,0,0,0-.377-2.258,2.086,2.086,0,0,0-1.749-.811A2.376,2.376,0,0,0,4.551,5.178a.652.652,0,0,1-.539.57L.987,5.424a.552.552,0,0,1-.465-.653C1.219,1.105,4.529,0,7.493,0a7.068,7.068,0,0,1,4.7,1.552c1.517,1.416,1.372,3.306,1.372,5.362v4.858a4.263,4.263,0,0,0,1.175,2.889.586.586,0,0,1-.009.829c-.636.531-1.767,1.517-2.39,2.069l-.009-.009" transform="translate(0 0)" fill="#fff" fill-rule="evenodd"/>
        </svg>
    );
};
    
Amazon.propTypes = {
    className: PropTypes.string,
};