import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

import { Amazon } from "../icons/Amazon";

import classNames from "classnames/bind"; 
import styles from "./AffiliateLink.module.scss";

class AffiliateLink extends React.Component {
    render() {
        const {
            className,
            isFullsize,
            image,
            title,
            url,
        } = this.props;

        const cx = classNames.bind(styles);

        const classes = cx(
            "wrapper",
            className,
            isFullsize ? "full" : "compact"
        );

        return (
            <a className={classes} href={url} target="_blank" rel="noreferrer">
                {isFullsize && image &&
                    <GatsbyImage
                        image={image}
                        className={styles.image}
                        alt={title}
                        title={title}
                        imgStyle={{ objectFit: "contain" }} />
                }
                <div className={styles.copy}>
                    <div className={styles.heading}>
                        <h3 className={styles.title}>{title}{!isFullsize && <span> at <Amazon className={styles.icon} /></span>}</h3>
                    </div>
                    {isFullsize &&
                        <React.Fragment>
                            <p className={styles.support}>When you make a purchase via our link, the small referral fee will help support the site.</p>
                            <div className={styles.button}>
                                Check prices at <Amazon className={styles.icon} />
                            </div>
                        </React.Fragment>
                    }
                </div>
            </a>
        );
    }
};

AffiliateLink.propTypes = {
    className: PropTypes.string,
    isFullsize: PropTypes.bool,
    image: PropTypes.object,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

AffiliateLink.defaultProps = {
    isFullsize: true
};

export default AffiliateLink;